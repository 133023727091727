import styled from "styled-components";

export const ContainerRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  align-items: center;
`;

export const ContainerCenter = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fonts.roboto_bold}; ;
`;

export const InConstruction = styled.img`
  margin-top: 30px;
  width: 600px;
  height: 500px;
`;

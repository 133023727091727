import styled from "styled-components";
import { lighten, rgba } from "polished";

export const Container = styled.div`
  width: 400px;
  max-height: 405px;
  cursor: pointer;
`;

export const Cover = styled.img`
  width: 100%;
  height: 300px;
  border-radius: 8px;
`;
export const InfoContainer = styled.div`
  width: 100%;
  min-height: 87px;
  padding-left: 10px;
`;
export const Title = styled.h4`
  color: ${({ theme }) => theme.colors.primary};
`;

export const Description = styled.p`
  overflow: hidden; /* remove o estouro do elemento */
  text-overflow: ellipsis; /* adiciona “...” no final */
  display: -webkit-box;
  -webkit-line-clamp: 1; /* quantidade de linhas */
  -webkit-box-orient: vertical; /* conteúdo será apresentado verticalmente */
  color: ${({ theme }) => theme.colors.primary};
`;
export const DateAndAuthorContainer = styled.div`
  display: flex;
  padding-right: 10px;
  flex-direction: row;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.primary};
`;
export const Author = styled.div``;

export const CreatedDate = styled.div``;

export const TagsContainer = styled.div``;

export const ItemNews = styled.li`
  display: flex;
  align-items: center;
  width: 90%;
  height: 200px;
  border-radius: 12px;
  margin-bottom: 15px;
  padding-left: 15px;
  border: 2px solid ${({ theme }) => theme.colors.border_secondary};

  &:hover {
    cursor: pointer;
    transition: 0.5s;
    background-color: ${({ theme }) => rgba(theme.colors.primary, 0.1)};
  }
`;

export const TitleItem = styled.text`
  font-size: 20px;
  font-weight: 700;
`;

export const InfosItem = styled.text`
  align-self: flex-end;
  margin: 0px 50px;
  font-size: 18px;
  font-weight: 700;
  color: ${({ theme }) => rgba(theme.colors.primary, 0.3)};
`;

export const DescriptionItem = styled.text`
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 400;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
  text-align: justify;
`;

export const CoverImage = styled.img`
  height: 180px;
  width: 180px;
`;

export const ContentItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90%;
  margin-left: 20px;
`;

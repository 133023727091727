import styled from "styled-components";

interface NewProps {
  backgroundImage: string;
}

export const ContainerRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  align-items: center;
`;

export const ContainerCenter = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Content = styled.div`
  display: grid;
  width: 75%;
  grid-template-columns: 60% 415px;
  grid-gap: 10px;
  justify-content: center;
  grid-gap: 40px;
`;

export const MainNew = styled.section`
  width: 880px;
  height: 600px;
`;

export const AsideNews = styled.section`
  display: grid;
  grid-row-gap: 20px;
  width: 415px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`;

export const BackgroundImageNew = styled.div<NewProps>`
  display: flex;
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 46px 25px;
  align-items: flex-end;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
`;

export const BackgroundImageNewAside = styled.div<NewProps>`
  display: flex;
  width: 415px;
  height: 100%;
  cursor: pointer;
  padding: 40px 25px;
  align-items: flex-end;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
`;

export const TitleContainer = styled.div`
  padding: 10px;
  color: ${({ theme }) => theme.colors.shape};
  font-family: ${({ theme }) => theme.fonts.roboto_bold};
  background-color: ${({ theme }) => theme.colors.primary};
`;

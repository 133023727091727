import React from 'react';

import { SideMenu } from '../../components/SideMenu';
import { Container } from './styles';

export const Home = () => {
  return(
    <Container>
      <SideMenu home/>
    </Container>
  )
}
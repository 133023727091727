import styled from "styled-components";

interface ButtonProps {
  active: boolean;
}

export const TextButton = styled.p`
  margin: 0;
`;

export const HeaderContainer = styled.header`
  width: 100%;
  display: flex;
  padding-top: 60px;
  justify-content: center;
`;

export const Header = styled.div`
  width: 75%;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
`;

export const Logo = styled.img`
  width: 80%;
  margin-left: -20px;
`;
export const LogoContainer = styled.div``;

export const ButtonsContainer = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;
`;

export const Button = styled.button<ButtonProps>`
  color: ${({ theme }) => theme.colors.secondary};
  border-width: ${({ active }) => (active ? 1 : 0)}px;
  border-color: ${({ theme, active }) =>
    active ? theme.colors.primary : "transparent"};
  border-radius: ${({ active }) => (active ? 25 : 0)}px;
  margin-right: 5px;
  width: 90px;
  padding: 25px 0px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonJoinUs = styled.button<ButtonProps>`
  width: 230px;
  height: 60px;
  color: ${({ theme }) => theme.colors.shape};
  border-width: ${({ active }) => (active ? 1 : 0)}px;
  border-color: ${({ theme, active }) =>
    active ? theme.colors.primary : "transparent"};
  border-width: 0;
  margin-left: 20px;
  border-radius: 50px;
  background-color: ${({ theme }) => theme.colors.primary};
`;

import React, { useState } from "react";
import { useAuth } from "../../../hooks/AuthContext";
import { Input } from "../../components/Input";
import { SideMenu } from "../../components/SideMenu";

import {
  Container,
  Content,
  Header,
  InputContainer,
  Form,
  ButtonSubmit,
  StatusText,
  Loader,
} from "./styles";

interface StatusProps {
  message: string;
  status: boolean;
}
export function CreateTeam() {
  const { RegisterMembers } = useAuth();
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [registration, setRegistration] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<StatusProps>({} as StatusProps);

  async function handleRegisterMember() {
    if (
      name.length === 0 ||
      role.length === 0 ||
      email.length === 0 ||
      registration.length === 0
    ) {
      return setStatus({
        message: "Por favor preencha todos os campos",
        status: false,
      });
    } else {
      try {
        setLoading(true);
        await RegisterMembers({ email, name, registration, role }).then(
          (res) => {
            if (res.statusText === "Created") {
              setStatus({ message: "Membro adionado a equipe", status: true });
            } else {
              setStatus({
                message: "Opá, algo deu errado, tente novamente",
                status: false,
              });
            }
          }
        );
        setLoading(false);
      } catch (error) {
        setStatus({
          message: "Opá, algo deu errado, tente novamente",
          status: false,
        });
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <Container>
      <SideMenu team />
      <Content>
        <Header>
          <text> Adicionar novo membro da equipe </text>
        </Header>
        <Form>
          <InputContainer>
            <text> Nome: </text>
            <Input
              placeholder="Nome do novo membro"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setStatus({ message: "", status: false });
              }}
            />
          </InputContainer>

          <InputContainer>
            <text> Matricula: </text>
            <Input
              placeholder="Matricula do novo membro"
              value={registration}
              onChange={(e) => {
                setRegistration(e.target.value);
                setStatus({ message: "", status: false });
              }}
            />
          </InputContainer>

          <InputContainer>
            <text> Cargo: </text>
            <Input
              placeholder="Cargo no novo membro"
              value={role}
              onChange={(e) => {
                setRole(e.target.value);
                setStatus({ message: "", status: false });
              }}
            />
          </InputContainer>

          <InputContainer>
            <text> Email: </text>
            <Input
              placeholder="E-mail do novo membro"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setStatus({ message: "", status: false });
              }}
            />
          </InputContainer>

          <ButtonSubmit onClick={handleRegisterMember}>Adicionar</ButtonSubmit>
          {loading ? (
            <Loader />
          ) : (
            status.message !== "" && (
              <StatusText work={status.status}>{status.message}</StatusText>
            )
          )}
        </Form>
      </Content>
    </Container>
  );
}

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import LogoImage from "../../../assets/Logo-1.png";
import { useAuth } from "../../../hooks/AuthContext";
import {
  Container,
  FormContainer,
  LogoContainer,
  Logo,
  InputsContainer,
  Input,
  ButtonsContainer,
  MakeLoginButton,
  Loader,
  StatusText,
} from "./styles";

interface StatusProps {
  message: string;
  status: boolean;
}
export function Login() {
  const { signIn } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<StatusProps>({} as StatusProps);
  const navigate = useNavigate();
  const { state } = useLocation();

  async function handleLogin() {
    if (email.length === 0 || password.length === 0) {
      return setStatus({
        message: "Por favor preencha todos os campos",
        status: false,
      });
    } else {
      try {
        setLoading(true);
        await signIn({ email, password }).then((res) => {
          if (res.statusText !== "OK") {
            setStatus({
              message: "Opá, algo deu errado, tente novamente",
              status: false,
            });
          }
          navigate(state?.path || "/dashboard");
        });
        setLoading(false);
      } catch (error) {
        setStatus({
          message: "Opá, algo deu errado, tente novamente",
          status: false,
        });
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <Container>
      <FormContainer>
        <LogoContainer>
          <Logo src={LogoImage} />
        </LogoContainer>
        <InputsContainer>
          <Input
            placeholder="E-mail"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setStatus({ message: "", status: false });
            }}
          />

          <Input
            placeholder="Password"
            type="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setStatus({ message: "", status: false });
            }}
          />
        </InputsContainer>

        {loading ? (
          <Loader />
        ) : (
          <ButtonsContainer>
            <MakeLoginButton onClick={() => handleLogin()}>
              Login
            </MakeLoginButton>
          </ButtonsContainer>
        )}
        {status.message !== "" && (
          <StatusText work={status.status}>{status.message}</StatusText>
        )}
      </FormContainer>
    </Container>
  );
}

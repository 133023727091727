import React, { useContext, createContext, useState, ReactNode } from "react";

export interface pagesOption {
  page:
    | "INÍCIO"
    | "A REVISTA"
    | "ARTIGOS"
    | "PROJETOS"
    | "CONTATO"
    | "FAÇA PARTE";
}
interface SelectedPageContextProps {
  selectedPage: pagesOption;
  changeSelectedPage: (selected: pagesOption) => void;
}

interface SelectedPageProviderProps {
  children: ReactNode;
}

export const SelectedPageContext = createContext(
  {} as SelectedPageContextProps
);

function SelectedPageProvider({ children }: SelectedPageProviderProps) {
  const [selectedPage, setSelectedPage] = useState<pagesOption>({
    page: "A REVISTA",
  });

  function changeSelectedPage(selected: pagesOption) {
    setSelectedPage(selected);
  }

  return (
    <SelectedPageContext.Provider
      value={{
        selectedPage: selectedPage,
        changeSelectedPage,
      }}
    >
      {children}
    </SelectedPageContext.Provider>
  );
}

function useSelectedPage() {
  const context = useContext(SelectedPageContext);
  return context;
}

export { SelectedPageProvider, useSelectedPage };

import React, { useEffect } from "react";
import {
  ContainerCenter,
  ContainerRoot,
  Content,
  InConstruction,
  Title,
  HeaderLastNews,
  LastNewsContainer,
  DiversityContainer,
  NewsContent,
  HeaderDiversity,
  ButtonSeeMoreContainer,
  ButtonSeeMore,
} from "./styles";
import { HeaderComponent } from "../../components/Header";
import { useNews } from "../../hooks/NewsContext";
import { New } from "../../components/New";
import { useNavigate } from "react-router-dom";

export const News: React.FC = () => {
  console.log("Made with love by Krollitte & Kildrak");
  console.log("Coding the world to see if we understand it");

  const navigate = useNavigate();
  const { getNewsList, news } = useNews();

  useEffect(() => {
    function getNews() {
      getNewsList();
    }

    getNews();
  }, []);

  const diversityNew = news?.filter((item) => {
    return item.tags.includes("lgbt");
  });

  const recentNew = news[0]?.id === diversityNew[0]?.id ? news[1] : news[0];

  function handleNavigateToNewsList() {
    console.log("chegou aqui ");
    navigate(`/news/list`);
  }

  return (
    <ContainerRoot>
      <HeaderComponent showLogo={true} />
      <Content>
        <NewsContent>
          {recentNew && (
            <LastNewsContainer>
              <HeaderLastNews>ÚLTIMAS NOTÍCIAS</HeaderLastNews>
              <New
                key={recentNew.id}
                id={recentNew.id}
                author={recentNew.author}
                body={recentNew.body}
                cover={recentNew.cover}
                updated_at={String(recentNew.updated_at)}
                created_at={String(recentNew.created_at)}
                description={recentNew.description}
                reviewer={recentNew.reviewer}
                tags={recentNew.tags}
                title={recentNew.title}
              />
            </LastNewsContainer>
          )}
          {diversityNew.length > 0 ? (
            <DiversityContainer>
              <HeaderDiversity>VIVA A DIVERSIDADE</HeaderDiversity>
              <New
                key={diversityNew && diversityNew[0].id}
                id={diversityNew && diversityNew[0].id}
                author={diversityNew && diversityNew[0].author}
                body={diversityNew && diversityNew[0].body}
                cover={diversityNew && diversityNew[0].cover}
                updated_at={diversityNew && String(diversityNew[0].updated_at)}
                created_at={diversityNew && String(diversityNew[0].created_at)}
                description={diversityNew && diversityNew[0].description}
                reviewer={diversityNew && diversityNew[0].reviewer}
                tags={diversityNew && diversityNew[0].tags}
                title={diversityNew && diversityNew[0].title}
              />
            </DiversityContainer>
          ) : (
            ""
          )}
        </NewsContent>
      </Content>
      <ButtonSeeMoreContainer>
        <ButtonSeeMore
          onClick={() => {
            console.log("chegou ");
            handleNavigateToNewsList();
          }}
        >
          Veja mais
        </ButtonSeeMore>
      </ButtonSeeMoreContainer>
    </ContainerRoot>
  );
};

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IRequestNews, useNews } from "../../../hooks/NewsContext";

import { SideMenu } from "../../components/SideMenu";
import {
  Container,
  Content,
  Header,
  ItemNews,
  ListNews,
  TitleItem,
  CoverImage,
  ContentItem,
  DescriptionItem,
  InfosItem,
  ButtonAdd,
  Loader,
} from "./styles";

interface StatusProps {
  message: string;
  status: boolean;
}
export const NewsDB = () => {
  const { getNewsList, news } = useNews();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<StatusProps>({} as StatusProps);

  useEffect(() => {
    async function getNews() {
      try {
        setLoading(true);
        await getNewsList();
        setLoading(false);
      } catch (error) {
        setStatus({
          message: String(error),
          status: false,
        });
      } finally {
        setLoading(false);
      }
    }

    getNews();
  }, []);

  return (
    <Container>
      <SideMenu news />
      <Content>
        <Header>
          <text>Artigos</text>
          <ButtonAdd onClick={() => navigate("/dashboard/news/create")}>
            <text>Novo Artigo</text>
          </ButtonAdd>
        </Header>

        <ListNews>
          {loading ? (
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Loader />
            </div>
          ) : (
            news.map((item: IRequestNews, index: number) => {
              return (
                <ItemNews
                  key={index}
                  onClick={() => navigate(`/dashboard/news/read/${item.id}`)}
                >
                  <CoverImage src={item.cover} />
                  <ContentItem>
                    <TitleItem>{item.title}</TitleItem>
                    <DescriptionItem>{item.description}</DescriptionItem>

                    <InfosItem>{`Autor: ${item.author}`}</InfosItem>
                    <InfosItem>{`Revisão: ${item.reviewer}`}</InfosItem>
                  </ContentItem>
                </ItemNews>
              );
            })
          )}
        </ListNews>
      </Content>
    </Container>
  );
};

import styled from "styled-components";
import { rgba } from "polished";

export const Container = styled.input`
  width: 300px;
  height: 40px;
  border: ${({ theme }) => `solid 2px ${theme.colors.border}`};
  border-radius: 8px;
  padding: 5px 10px;

  &:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
    transition: 0.5s;

    border: ${({ theme }) => `solid 2px ${rgba(theme.colors.primary, 0.3)}`};
  }
`;

export const ContainerTextArea = styled.textarea`
  width: 600px;
  height: 100px;
  border: ${({ theme }) => `solid 2px ${theme.colors.border}`};
  border-radius: 8px;
  padding: 5px 10px;

  &:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
    transition: .5s;

    border: ${({ theme }) => `solid 2px ${rgba(theme.colors.primary, .3)}`};
  }
  
`;

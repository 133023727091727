import React, { createContext, ReactNode, useContext, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { api } from "../services/api";
import { useAuth } from "./AuthContext";
import { OptionsType } from "../Dashboard/components/MultiSelect";

type TagsProviderProps = {
  children: ReactNode;
};

interface TagsProps extends OptionsType {
  id: string;
  name: string;
  created_at: string;
}

type TagsContextData = {
  getTagsList: () => Promise<AxiosResponse>;
  createNewTag: (name: string) => Promise<AxiosResponse>;
  tags: TagsProps[];
};

const TagsContext = createContext({} as TagsContextData);
function TagsProvider({ children }: TagsProviderProps) {
  const { user } = useAuth();
  const [tags, setTags] = useState<TagsProps[]>([] as TagsProps[]);

  async function getTagsList() {
    try {
      const response = await api.get("tags", {
        headers: {
          authorization: `Bearer ${user.token}`,
        },
      });

      setTags(response.data);

      return response;
    } catch (error) {
      const err = error as AxiosError;
      const dataError = err.response as AxiosResponse;
      throw new Error(dataError.data.message);
    }
  }

  async function createNewTag(name: string) {
    try {
      const response = await api.post("tags", {
        name: name,
        headers: {
          authorization: `Bearer ${user.token}`,
        },
      });

      return response;
    } catch (error) {
      const err = error as AxiosError;
      const dataError = err.response as AxiosResponse;
      throw new Error(dataError.data);
    }
  }

  return (
    <TagsContext.Provider value={{ getTagsList, tags, createNewTag }}>
      {children}
    </TagsContext.Provider>
  );
}

function useTags() {
  const context = useContext(TagsContext);
  return context;
}

export { useTags, TagsProvider };

import DOMPurify from "dompurify";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { HeaderComponent } from "../../components/Header";
import { api } from "../../services/api";

import {
  Container,
  Content,
  ImagePreview,
  ButtonSubmit,
  ContainerRoot,
} from "./styles";

interface IRequestNews {
  id: string;
  author: string;
  body: string;
  cover: string;
  description: string;
  title: string;
  reviewer: string;
  tags: string[];
  created_at: Date;
  updated_at: Date;
  canceled_at: Date;
}

export const NewsReadNoAuth = () => {
  const { id } = useParams();

  const [news, setNews] = useState<IRequestNews>();

  const getNews = async () => {
    const { data } = await api.get(`/news/id/${id}`);
    console.log(data);
    setNews(data);
  };

  useEffect(() => {
    getNews();
  }, []);

  const createMarkup = (html: any) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  return (
    <ContainerRoot>
      <HeaderComponent showLogo={true} />
      <Container>
        <Content>
          <h1>{news?.title}</h1>
          <h4 style={{ margin: "50px 0" }}>{news?.description}</h4>
          <ImagePreview src={news?.cover} />
          <div
            className="preview"
            dangerouslySetInnerHTML={createMarkup(news?.body)}
          ></div>
          <h6>{`Por ${news?.author}`}</h6>
          <h6>{`Revisão: ${news?.reviewer}`}</h6>
        </Content>
      </Container>
    </ContainerRoot>
  );
};

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  IoHomeOutline,
  IoNewspaperOutline,
  IoPersonOutline,
  IoPricetagOutline,
} from "react-icons/io5";

import { Container, Brand, ListItems, Item } from "./styles";

import logo from "../../../assets/Logo-1.png";

type Props = {
  home?: boolean;
  team?: boolean;
  news?: boolean;
  tags?: boolean;
};

export const SideMenu = ({
  home = false,
  team = false,
  news = false,
  tags = false,
}: Props) => {
  const [selectedHome, setSelectedHome] = useState(home);
  const [selectedTeam, setSelectedTeam] = useState(team);
  const [selectedNews, setSelectedArticles] = useState(news);
  const [selectedTags, setSelectedTags] = useState(tags);

  const navigate = useNavigate();

  return (
    <Container>
      <Brand src={logo} alt="Logo" />
      <ListItems>
        <Item select={selectedHome} onClick={() => navigate("/dashboard")}>
          <IoHomeOutline size={25} />
          <text> Inicio </text>
        </Item>
        <Item select={selectedNews} onClick={() => navigate("/dashboard/news")}>
          <IoNewspaperOutline size={25} />
          <text> Artigos </text>
        </Item>
        <Item select={selectedTeam} onClick={() => navigate("/dashboard/team")}>
          <IoPersonOutline size={25} />
          <text> Equipe </text>
        </Item>
        <Item select={selectedTags} onClick={() => navigate("/dashboard/tags")}>
          <IoPricetagOutline size={25} />
          <text> Tags </text>
        </Item>
      </ListItems>
    </Container>
  );
};

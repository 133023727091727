import styled from "styled-components";
export const Container = styled.div``;
export const ContainerBackground = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  margin-top: 25px;
`;
export const PrincipalImage = styled.img`
  margin-left: 12%;
  margin-right: 8%;
`;
export const ContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const Text = styled.text`
  font-weight: 700;
  font-size: 24px;
  color: #582c83;
`;
export const InfoText = styled.p`
  margin-top: 8px;
  font-size: 14px;
  width: 500px;
  color: #00aca0;
  font-weight: 400;
`;
export const InfoText2 = styled.p`
  margin-top: 10px;
  font-size: 14px;
  width: 400px;
  color: #582c83;
  font-weight: 300;
`;
export const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
export const ContainerPhoto = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const ContainerRounded = styled.div`
  display: flex;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background-color: #00aca0;
  background-size: cover;
`;
export const PhotoName = styled.text`
  font-size: 14px;
  width: 250px;
  color: #582c83;
  text-align: center;
  margin-top: 10px;
`;

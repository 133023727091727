import React, { InputHTMLAttributes, TextareaHTMLAttributes } from "react";

import { Container, ContainerTextArea } from "./styles";

export function Input({...rest}: InputHTMLAttributes<HTMLInputElement>){
  return (
    <Container {...rest}>
      
    </Container>
  )
} 

export function TextArea({...rest}: TextareaHTMLAttributes<HTMLTextAreaElement>){
  return (
    <ContainerTextArea {...rest}>
      
    </ContainerTextArea>
  )
} 
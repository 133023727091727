import React, { useEffect } from "react";
import { ContainerRoot, Content, NewsContent } from "./styles";
import { HeaderComponent } from "../../components/Header";
import { useNews } from "../../hooks/NewsContext";
import { New } from "../../components/New";

import { useTags } from "../../hooks/TagsContext";

export const NewsList: React.FC = () => {
  console.log("Made with love by Krollitte & Kildrak");
  console.log("Coding the world to see if we understand it");
  const { getTagsList } = useTags();

  const { getNewsList, news } = useNews();

  useEffect(() => {
    function getNews() {
      getNewsList();
    }

    function getTags() {
      getTagsList();
    }

    getNews();
    getTags();
  }, []);

  return (
    <ContainerRoot>
      <HeaderComponent showLogo={true} />
      <Content>
        <NewsContent>
          {news.map((item) => {
            return (
              <New
                key={item.id}
                id={item.id}
                author={item.author}
                body={item.body}
                cover={item.cover}
                updated_at={String(item.updated_at)}
                created_at={String(item.created_at)}
                description={item.description}
                reviewer={item.reviewer}
                tags={item.tags}
                title={item.title}
              />
            );
          })}
        </NewsContent>
      </Content>
    </ContainerRoot>
  );
};

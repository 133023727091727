import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  ButtonJoinUs,
  ButtonsContainer,
  Header,
  HeaderContainer,
  Logo,
  LogoContainer,
  TextButton,
} from "./styles";

import LogoPng from "../../assets/Logo-1.png";
import { useSelectedPage } from "../../hooks/selectedPage";

interface Props {
  showLogo: Boolean;
}
export const HeaderComponent = ({ showLogo }: Props) => {
  let navigate = useNavigate();
  const { selectedPage, changeSelectedPage } = useSelectedPage();

  return (
    <HeaderContainer>
      <Header>
        <LogoContainer>
          {showLogo ? (
            <Logo src={LogoPng} alt="Logo Revista Viva a Universidade" />
          ) : (
            ""
          )}
        </LogoContainer>

        <ButtonsContainer>
          <Button
            active={selectedPage.page === "INÍCIO" ? true : false}
            onClick={() => {
              changeSelectedPage({ page: "INÍCIO" });
              navigate("/home");
            }}
          >
            INÍCIO
          </Button>
          <Button
            active={selectedPage.page === "A REVISTA" ? true : false}
            onClick={() => {
              changeSelectedPage({ page: "A REVISTA" });
              navigate("/revista");
            }}
          >
            <TextButton>A REVISTA</TextButton>
          </Button>
          <Button
            active={selectedPage.page === "ARTIGOS" ? true : false}
            onClick={() => {
              changeSelectedPage({ page: "ARTIGOS" });
              navigate("/artigos");
            }}
          >
            <TextButton>ARTIGOS</TextButton>
          </Button>
          <Button
            active={selectedPage.page === "PROJETOS" ? true : false}
            onClick={() => {
              changeSelectedPage({ page: "PROJETOS" });
              navigate("/projetos");
            }}
          >
            <TextButton>PROJETOS</TextButton>
          </Button>
          <Button
            active={selectedPage.page === "CONTATO" ? true : false}
            onClick={() => {
              changeSelectedPage({ page: "CONTATO" });
              navigate("/contato");
            }}
          >
            <TextButton>CONTATO</TextButton>
          </Button>
          <ButtonJoinUs
            active={selectedPage.page === "FAÇA PARTE" ? true : false}
            onClick={() => {
              changeSelectedPage({ page: "FAÇA PARTE" });
              navigate("/facaparte");
            }}
          >
            FAÇA PARTE
          </ButtonJoinUs>
        </ButtonsContainer>
      </Header>
    </HeaderContainer>
  );
};

import styled from "styled-components";
import { rgba } from "polished";

export const Container = styled.div`
  display: flex;
  width: 300px;
  min-height: 40px;
  border: ${({ theme }) => `2px solid ${theme.colors.border}`};
  border-radius: 8px;
  background-color: transparent;
  align-items: center;
  justify-content: space-between;

  &:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
    transition: 0.5s;

    border: ${({ theme }) => `solid 2px ${rgba(theme.colors.primary, 0.3)}`};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 90%;
  padding: 5px;
`;

export const SelectedItem = styled.div`
  display: flex;
  width: max-content;
  border-radius: 8px;
  padding: 5px 8px;
  background-color: ${({ theme }) => theme.colors.border};
  align-items: center;
  justify-items: center;
  margin-left: 3px;
  margin-bottom: 5px;

  > text {
    font-size: 12px;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  height: 100%;
  &:hover {
    cursor: pointer;
  }
`;

export const Dropdown = styled.ul`
  display: flex;
  padding: 0;
  flex-direction: column;
  width: 300px;
  border-bottom-left-radius: 8px;
  border-end-end-radius: 8px;
  border: ${({ theme }) => `2px solid ${theme.colors.border}`};
`;

export const RemoveIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`;

export const Item = styled.li`
  width: 100%;
  padding: 10px;
  margin: 0;
  list-style-type: none;
  margin-bottom: 3px;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => rgba(theme.colors.primary, 0.1)};
  }

  > text {
    font-size: 14px;
    font-family: ${({ theme }) => theme.fonts.roboto_regular};
  }
`;

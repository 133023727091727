import styled from "styled-components";
import { rgba } from "polished";

type Props = {
  select?: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 20vw;
  border-right: ${({ theme }) =>
    `2px solid ${rgba(theme.colors.primary, 0.1)}`};
`;

export const Brand = styled.img.attrs({
  resizeMode: "contain",
})`
  margin: 50px 0;
`;

export const ListItems = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`;

export const Item = styled.button<Props>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 95%;
  height: 40px;
  padding: 5px 30px;
  border: 0;
  border-left: ${({ select, theme }) =>
    select ? `4px solid ${theme.colors.primary}` : 0};
  border-top-right-radius: 5px;
  border-end-end-radius: 5px;
  background-color: transparent;
  transition: 0.3s;
  margin: 5px 0;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => rgba(theme.colors.primary, 0.1)};
  }

  > text {
    font-size: 16px;
    color: #000;
    font-weight: 500;
    margin-left: 30px;
  }
`;

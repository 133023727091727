import React from "react";

import { BrowserRouter } from "react-router-dom";
import { MagazineRoute } from "./routes/index";
import { ThemeProvider } from "styled-components";
import "./index.css";
import theme from "./styles/theme";
import { SelectedPageProvider } from "./hooks/selectedPage";
import { AuthProvider } from "./hooks/AuthContext";
import { TagsProvider } from "./hooks/TagsContext";
import { NewsProvider } from "./hooks/NewsContext";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <TagsProvider>
          <SelectedPageProvider>
            <NewsProvider>
              <BrowserRouter>
                <MagazineRoute />
              </BrowserRouter>
            </NewsProvider>
          </SelectedPageProvider>
        </TagsProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;

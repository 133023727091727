import React, { createContext, ReactNode, useContext, useState } from "react";
import { AxiosResponse } from "axios";
import { api } from "../services/api";

type AuthProviderProps = {
  children: ReactNode;
};

interface RegistrationData {
  name: string;
  role: string;
  email: string;
  registration: string;
}

interface LoginData {
  email: string;
  password: string;
}

interface UserProps {
  name: string;
  email: string;
}

interface UserData {
  token: string;
  user: UserProps;
}

type AuthContextData = {
  signIn: ({ email, password }: LoginData) => Promise<AxiosResponse>;
  RegisterMembers: ({
    email,
    name,
    registration,
    role,
  }: RegistrationData) => Promise<AxiosResponse>;
  user: UserData;
  signed: boolean;
};

const AuthContext = createContext({} as AuthContextData);
function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<UserData>({} as UserData);
  const [signed, setSigned] = useState(false);

  async function signIn({ email, password }: LoginData) {
    try {
      const response = await api.post("sessions", { email, password });
    
      setUser({ token: response.data.token, user: response.data.contributor });

      const userLocal: UserData = {
        token: response.data.token,
        user: response.data.contributor,
      };

      localStorage.setItem("@Anima/User", JSON.stringify(userLocal));
      setSigned(true);

      return response;
    } catch (error) {
      throw new Error("Opa, algo de errado aconteceu");
    }
  }

  async function RegisterMembers({
    name,
    role,
    email,
    registration,
  }: RegistrationData) {
    try {
      const response = await api.post("contributors", {
        email,
        name,
        register: registration,
        type: role,
      });
      return response;
    } catch (error) {
      throw new Error("Opa, algo de errado aconteceu");
    }
  }

  return (
    <AuthContext.Provider value={{ signIn, RegisterMembers, user, signed }}>
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);
  return context;
}

export { useAuth, AuthProvider };

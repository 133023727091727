import React from "react";
import format from "date-fns/format";
import { ptBR } from "date-fns/locale";

import {
  Container,
  Cover,
  InfoContainer,
  Title,
  Description,
  DateAndAuthorContainer,
  Author,
  CreatedDate,
  TagsContainer,
} from "./styles";
import { useNavigate } from "react-router-dom";

interface NewProps {
  id: string;
  author: string;
  body: string;
  cover: string;
  created_at: string;
  description: string;
  reviewer: string;
  tags: string[];
  title: string;
  updated_at: string;
}
export function New({
  id,
  author,
  body,
  cover,
  created_at,
  description,
  reviewer,
  tags,
  title,
  updated_at,
}: NewProps) {
  const navigate = useNavigate();
  const formatedDate = format(new Date(created_at), "dd/MM/yyyy 'às' HH:mm ");

  function handleReadNew() {
    navigate(`/news/read/${id}`);
  }

  return (
    <Container onClick={() => handleReadNew()}>
      <Cover src={cover} />
      <InfoContainer>
        <Title>{title}</Title>

        <Description>{description}asdadadad</Description>

        <DateAndAuthorContainer>
          <Author>{author}</Author>
          <CreatedDate>{formatedDate}</CreatedDate>
        </DateAndAuthorContainer>
      </InfoContainer>
    </Container>
  );
}

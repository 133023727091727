import React, { ReactNode } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

// Revista
import { HomePage } from "../pages/HomePage";
import { Contact } from "../pages/Contact";
import { Magazine } from "../pages/Magazine";
import { News } from "../pages/News";
import { Projects } from "../pages/Projects";
import { Subscribe } from "../pages/Subscribe";
import { NewsReadNoAuth } from "../pages/NewsRead";

// Dashboard
import { Home } from "../Dashboard/pages/Home";
import { NewsDB } from "../Dashboard/pages/News";
import { NewsRead } from "../Dashboard/pages/NewsRead";
import { Team } from "../Dashboard/pages/Team";
import { CreateNews } from "../Dashboard/pages/CreateNews";
import { CreateTeam } from "../Dashboard/pages/CreateTeam";
import { CreateTags } from "../Dashboard/pages/CreateTags";
import { useAuth } from "../hooks/AuthContext";
import { Login } from "../Dashboard/pages/Login";
import { NewsList } from "../pages/NewsList";

interface Props {
  children: ReactNode;
}

function RequireAuth({ children }: Props) {
  const { signed } = useAuth();
  const userString = localStorage.getItem("@Anima/User");
  const localUser = JSON.parse(String(userString));

  return signed === true || localUser ? (
    <>{children}</>
  ) : (
    <Navigate to="/dashboard/login" replace />
  );
}

export function MagazineRoute() {
  return (
    <Routes>
      <Route path="/" element={<Magazine />} />
      <Route path="/revista" element={<Magazine />} />
      <Route path="/Home" element={<HomePage />} />
      <Route path="/contato" element={<Contact />} />
      <Route path="/artigos" element={<News />} />
      <Route path="/projetos" element={<Projects />} />
      <Route path="/facaparte" element={<Subscribe />} />
      <Route path="/dashboard/login" element={<Login />} />
      <Route path="/news/read/:id" element={<NewsReadNoAuth />} />
      <Route path="/news/list" element={<NewsList />} />

      <Route
        path="/dashboard"
        element={
          <RequireAuth>
            <Home />
          </RequireAuth>
        }
      />
      <Route
        path="/dashboard/news"
        element={
          <RequireAuth>
            <NewsDB />
          </RequireAuth>
        }
      />
      <Route
        path="/dashboard/news/create"
        element={
          <RequireAuth>
            <CreateNews />
          </RequireAuth>
        }
      />
      <Route
        path="/dashboard/news/read/:id"
        element={
          <RequireAuth>
            <NewsRead />
          </RequireAuth>
        }
      />
      <Route
        path="/dashboard/team"
        element={
          <RequireAuth>
            <CreateTeam />
          </RequireAuth>
        }
      />
      {/* <Route
        path="/dashboard/teams/create"
        element={
          <RequireAuth>
            <CreateTeam />
          </RequireAuth>
        }
      /> */}
      <Route
        path="/dashboard/tags"
        element={
          <RequireAuth>
            <CreateTags />
          </RequireAuth>
        }
      />
    </Routes>
  );
}

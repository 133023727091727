import React from "react";

import {
  Container,
  ContainerBackground,
  ContainerColumn,
  ContainerPhoto,
  ContainerRounded,
  ContainerRow,
  InfoText,
  InfoText2,
  PhotoName,
  PrincipalImage,
  Text,
} from "./styles";
import background from "../../assets/bakc.png";
import Leidiane from "../../assets/LeidianeQueiroz.jpeg";
import Idevaldo from "../../assets/IdevaldoJose.jpeg";
import Antonio from "../../assets/AntonioNetto.jpg";
import Valter from "../../assets/ValterLima.jpeg";
import { HeaderComponent } from "../../components/Header";

export const Magazine: React.FC = () => {
  console.log("Made with love by Krollitte & Kildrak");
  console.log("Coding the world to see if we understand it");
  return (
    <>
      <HeaderComponent showLogo />
      <Container>
        <ContainerBackground>
          <PrincipalImage
            className="principal-image"
            src={background}
            alt="background"
            width="500"
            height="600"
          />
          <ContainerColumn className="container-column">
            <Text>Um novo projeto acaba de nascer!</Text>
            <InfoText>
              Sejam bem-vindos. Essa é a Revista Viva a Universidade Nossa
              proposta é trazer até você, nosso leitor, muito conteúdo relevante
              sobre as novidades da Unifacs, matérias sobre tendencias de
              mercado e informações jornalisticas das mais diversas áreas.
            </InfoText>
            <InfoText>
              Sejam bem-vindos. Essa é a Revista Viva a Universidade Nossa
              proposta é trazer até você, nosso leitor, muito conteúdo relevante
              sobre as novidades da Unifacs, matérias sobre tendencias de
              mercado e informações jornalisticas das mais diversas áreas.
            </InfoText>
            <InfoText>
              Este será nosso ponto de encontro para aquela sua parada do dia,
              enquanto toma um café ou senta para relaxar, e te fazemos
              companhia através de conteúdos leves, saudáveis e de muito
              conhecimento e aprendizado.
            </InfoText>
            <InfoText>
              A Revista Viva a Universidade é feita a quatro mãos, ou melhor
              dizendo, entre trés projetos de extensão e uma coordenação da
              Unillacs o Centro de Empreendedorismo e Inovação (CEI); a Agência
              de Noticias Avera, a Agencia Escola Ascendo, e a Coordenação da
              Grande Area 3, de Feira de Santana
            </InfoText>
            <InfoText>
              importante lembrar que cada um destes projetos de extensão
              composto por nossos alunos da Unifacs Por isso, este produto e, na
              pratica o esforço dedicado destes alunos, com a supervisão de seus
              coordenadores Vamos estar sempre aqui batendo um papo com você. E
              você com a gente
            </InfoText>
            <InfoText>
              Venha participe desta nova comunidade e Viva a Universidade!
              Abraços virtuais
            </InfoText>
            <InfoText2>
              Prof° Leidiene Queiroz - CEI <br />
              Prof° Idevaldo Jose ASCENDO <br />
              Prof° Antonio Netto - AVERA <br />
              Prof° Valter Lima - Coordenador de Grande Área 3 <br />
            </InfoText2>
          </ContainerColumn>
        </ContainerBackground>
        <ContainerRow>
          <ContainerPhoto>
            <ContainerRounded style={{ backgroundImage: `url(${Leidiane})` }} />
            <PhotoName>Prof° Leidiene Queiroz - CEI</PhotoName>
          </ContainerPhoto>
          <ContainerPhoto>
            <ContainerRounded style={{ backgroundImage: `url(${Idevaldo})` }} />
            <PhotoName>Prof° Idevaldo Jose ASCENDO</PhotoName>
          </ContainerPhoto>
          <ContainerPhoto>
            <ContainerRounded style={{ backgroundImage: `url(${Antonio})` }} />
            <PhotoName>Prof° Antonio Netto - AVERA</PhotoName>
          </ContainerPhoto>
          <ContainerPhoto>
            <ContainerRounded style={{ backgroundImage: `url(${Valter})` }} />
            <PhotoName>
              Prof° Valter Lima - Coordenador de Grande Área 3
            </PhotoName>
          </ContainerPhoto>
        </ContainerRow>
      </Container>
    </>
  );
};

import React, { useState, useRef, useEffect } from "react";
import * as yup from "yup";
import { HashLoader } from "react-spinners";
import { SideMenu } from "../../components/SideMenu";
import { EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { Input, TextArea } from "../../components/Input";
import { MultiSelect } from "../../components/MultiSelect";

import { api } from "../../../services/api";

import {
  Container,
  Content,
  Header,
  TextRich,
  CoverContainer,
  CoverPickerContainer,
  CoverButtonContainer,
  Cover,
  Label,
  ImagePreview,
  InputConatainer,
  ButtonSubmit,
} from "./styles";

import placeholder from "../../../assets/placeholder-image.jpg";

import { OptionsType } from "../../components/MultiSelect";

import { AxiosError, AxiosResponse } from "axios";
import { useTags } from "../../../hooks/TagsContext";
import { useNews } from "../../../hooks/NewsContext";

import { useNavigate } from "react-router-dom";

type DataValidation = {
  cover: FileList;
  author: string;
  description: string;
  body: string;
  reviewer: string;
  option: OptionsType[];
};

type ResponseTag = {
  id: string;
  name: string;
  created_at: Date;
};

interface StatusProps {
  message: string;
  status: boolean;
}

export type FileEventTarget = Event & { files: FileList };

export const CreateNews = () => {
  const fileInput = useRef<any>();
  const { getTagsList, tags } = useTags();
  const { createANew } = useNews();

  const [cover, setCover] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [author, setAuthor] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [reviewer, setReviewer] = useState("");
  const [tagOption, setTagOption] = useState<OptionsType[]>([]);
  const [option, setOption] = useState<OptionsType[]>([]);
  const [body, setBody] = useState("");
  const [loadingGetTags, setLoadingGetTags] = useState(false);
  const [status, setStatus] = useState<StatusProps>({} as StatusProps);

  const navigate = useNavigate();

  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );

  async function getTags() {
    try {
      setLoadingGetTags(true);
      if(!tagOption || tagOption.length === 0){
        await getTagsList();
      
        console.log("TAGS", tagOption) 
        const data: OptionsType[] = tags.map((tag) => {
          return {
            name: tag.name,
            value: tag.id,
          };
        });

        setTagOption(data && data);
        setLoadingGetTags(false);
      }
    } catch (error) {
      setStatus({
        message: String(error),
        status: false,
      });
    } finally {
      setLoadingGetTags(false);
    }
  }


  useEffect(() => {
    getTags();
  }, [tagOption]);

  const handleClickInputFile = () => {
    fileInput.current.click();
  };

  const handleChange = (event: any) => {
    const target = event.target as HTMLInputElement;
    const fileUploaded = target.files;
    if (fileUploaded) {
      setCover(fileUploaded);
    }
  };

  const handleValidation = async (data: DataValidation) => {
    try {
      setLoading(true);
      let schema = yup.object().shape({
        description: yup.string().required(),
        author: yup.string().required(),
        body: yup.string().required(),
        reviewer: yup.string().required(),
        option: yup.array().required(),
      });

      const isValid = await schema.isValid(data, { abortEarly: false });
      if (isValid && cover) {
        handleSubmitNews();
      } else {
        alert("Todos os campos são obrigatórios");
        setLoading(false);
      }
    } catch (error) {
      alert("Todos os campos são obrigatórios");
      console.log(error);
    }
  };

  const handleSubmitNews = async () => {
    try {
      setLoading(true);
      const htmlText = convertToHTML(editorState.getCurrentContent());

      var newsData = new FormData();

      newsData.append("cover", cover[0]);
      newsData.append("author", author);
      newsData.append("body", htmlText);
      newsData.append("description", description);
      newsData.append("title", title);
      newsData.append("reviewer", reviewer);
      for (var i = 0; i < option.length; i++) {
        newsData.append("tags[]", option[i].name);
      }
      await createANew(newsData);

      setLoading(false);
      setBody(htmlText);

      navigate("/dashboard/news");
    } catch (error) {
      setStatus({
        message: String(error),
        status: false,
      });
    } finally {
      setLoading(false);
    }
  };
  const handleOnChange = ({ value, name }: OptionsType) => {
    const exist = option.find((item) => item.value === value);

    if (!exist) {
      setOption([...option, { name, value }]);
    }
  };

  const handleDelete = (value: string) => {
    const indexItem = option.findIndex((item) => item.value === value);

    const newOption = option;

    newOption.splice(indexItem, 1);

    setOption(newOption);
  };

  const createMarkup = (html: any) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  return (
    <Container>
      <SideMenu news />
      <Content>
        <Header>
          <text>Criar novo artigo</text>
        </Header>

        <Label>Foto para capa da noticia :</Label>
        <CoverContainer>
          <CoverPickerContainer>
            <Cover src={cover ? URL.createObjectURL(cover[0]) : placeholder} />
          </CoverPickerContainer>
          <CoverButtonContainer>
            <button onClick={handleClickInputFile}>Escolher Imagem</button>
            <input
              type="file"
              ref={fileInput}
              style={{ display: "none" }}
              onChange={handleChange}
            />
          </CoverButtonContainer>
        </CoverContainer>

        <InputConatainer>
          <Label>Titulo :</Label>
          <Input
            placeholder="Titulo do artigo"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />
        </InputConatainer>

        <InputConatainer>
          <Label>Descrição :</Label>
          <TextArea
            rows={5}
            cols={33}
            placeholder="Descrição resumida sobre a noticia"
            onChange={(e) => setDescription(e.target.value)}
            value={description}
          />
        </InputConatainer>

        <InputConatainer>
          <Label>Autor :</Label>
          <Input
            placeholder="Autor do artigo"
            onChange={(e) => setAuthor(e.target.value)}
            value={author}
          />
        </InputConatainer>

        <InputConatainer>
          <Label>Revisão :</Label>
          <Input
            placeholder="Responsável pela revisão"
            onChange={(e) => setReviewer(e.target.value)}
            value={reviewer}
          />
        </InputConatainer>
        <InputConatainer>
          <Label>Tags :</Label>
          <MultiSelect
            options={tagOption}
            onDelete={(value: string) => handleDelete(value)}
            onChange={({ name, value }: OptionsType) =>
              handleOnChange({ name, value })
            }
            values={option}
          />
        </InputConatainer>

        <Label>Corpo do artigo :</Label>
        <TextRich
          editorState={editorState}
          onEditorStateChange={setEditorState}
          toolbar={{
            options: ["inline", "blockType", "list", "image", "history"],
            inline: {
              options: ["bold", "italic", "underline", "monospace"],
            },
            blockType: {
              options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
            },
          }}
        />
        <ButtonSubmit
          onClick={() =>
            handleValidation({
              cover,
              author,
              description,
              body: convertToHTML(editorState.getCurrentContent()),
              reviewer,
              option,
            })
          }
        >
          {loading ? <HashLoader size={20} color={"#fff"} /> : "Enviar"}
        </ButtonSubmit>

        <Label>Preview</Label>

        <h1>{title}</h1>
        <h4 style={{ margin: "50px 0" }}>{description}</h4>
        {cover && (
          <ImagePreview
            src={cover ? URL.createObjectURL(cover[0]) : placeholder}
          />
        )}
        <div
          className="preview"
          dangerouslySetInnerHTML={createMarkup(
            convertToHTML(editorState.getCurrentContent())
          )}
        ></div>
      </Content>
    </Container>
  );
};

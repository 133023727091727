import styled, { css } from "styled-components";
import { Editor } from "react-draft-wysiwyg";
import { rgba, lighten } from "polished";

export const Container = styled.div`
  display: flex;
  width: 100vw;
  flex: 1;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  flex: 1;
  padding: 50px;
`;

export const Header = styled.div`
  width: 100%;
  margin-bottom: 38px;

  > text {
    font-size: 24px;
    font-weight: 700;
  }
`;

export const ButtonSubmit = styled.button`
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 40px;
  padding: 5px 30px;
  border: 0;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.primary};
  transition: 0.3s;
  margin: 36px 0;
  color: ${({ theme }) => theme.colors.shape};

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => lighten(0.1, theme.colors.primary)};
  }

  > text {
    font-size: 16px;
    color: #000;
    font-weight: 500;
    margin-left: 30px;
  }
`;

export const TextRich = styled(Editor).attrs({
  wrapperStyle: {
    width: "70vw",
    padding: "8px",
    border: "1px solid #ccc",
    borderRadius: "12px",
    marginBottom: "16px",
  },
  editorStyle: {
    padding: "8px",
    border: "1px solid #ccc",
    height: "300px",
    borderBottomLeftRadius: "12px",
    borderEndEndRadius: "12px",
    overflowY: "auto",
  },
  toolbarStyle: {
    border: "1px solid #ccc",
    borderTopLeftRadius: "12px",
    borderTopRightRadius: "12px",
  },
})``;

export const Label = styled.text`
  font-size: 17px;
  font-weight: 700;
  ${({ theme }) => css`
    font-family: ${theme.fonts.roboto_regular};
  `}
`;

export const CoverContainer = styled.div`
  width: 300px;
  height: 300px;
  border-radius: 8px;
  border: ${({ theme }) => `2px solid ${theme.colors.border}`};
  margin-bottom: 36px;
`;

export const CoverPickerContainer = styled.div`
  width: 100%;
  height: 80%;
  border-bottom: ${({ theme }) => `2px solid ${theme.colors.border}`};
  padding: 10px;
`;

export const InputConatainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 36px;
`;

export const Cover = styled.img.attrs({
  resizeMode: "contain",
})`
  width: 100%;
  height: 100%;
`;

export const ImagePreview = styled.img.attrs({
  resizeMode: "contain",
})`
  width: 80%;
  margin-bottom: 60px;
`;

export const BodyPreview = styled.text`
  font-size: 17px;
  font-weight: 700;
  ${({ theme }) => css`
    font-family: ${theme.fonts.roboto_regular};
  `};
`;

export const CoverButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;

  > button {
    border: 0;
    width: 100%;
    height: 40px;
    background-color: transparent;
    transition: 0.3s;
    border-radius: 8px;

    &:hover {
      cursor: pointer;
      background-color: ${({ theme }) => rgba(theme.colors.primary, 0.1)};
    }
  }
`;

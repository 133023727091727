import React from "react";

import LogoPng from "../../assets/Logo-1.png";
import {
  Container,
  ContactBox,
  ContactContainer,
  ImageContainer,
  Logo,
  Input,
  InputButton,
  InputTextArea,
  BackgroundWrapper,
  BackgroundImage,
  InputText,
} from "./styles";
import { HeaderComponent } from "../../components/Header";
import BackgroundContact from "../../assets/2062.png";

export const Contact: React.FC = () => {
  const showAlert = () => {
    alert("Mensagem enviada");
  };
  console.log("Made with love by Krollitte & Kildrak");
  console.log("Coding the world to see if we understand it");
  return (
    <>
      <HeaderComponent showLogo={false} />
      <Container>
        <ContactContainer>
          <ImageContainer>
            <Logo src={LogoPng} alt="Viva a Universidade" />
          </ImageContainer>
          <ContactBox>
            <Input placeholder="Nome Completo" />
            <Input placeholder="E-mail" />
            <InputTextArea placeholder="Digite sua mensagem" />

            <InputButton onClick={showAlert}>
              <InputText>Enviar</InputText>
            </InputButton>
          </ContactBox>
        </ContactContainer>
        <BackgroundWrapper>
          <BackgroundImage src={BackgroundContact} alt="backgroundImage" />
        </BackgroundWrapper>
      </Container>
    </>
  );
};

import styled from "styled-components";

export const ContainerRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  align-items: center;
`;

export const Content = styled.div`
  width: 75%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 100px;
`;

export const NewsContent = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const LastNewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 150px;
`;
export const HeaderLastNews = styled.div`
  height: 50px;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  font-size: 30px;
  color: ${({ theme }) => theme.colors.shape};
  font-family: ${({ theme }) => theme.fonts.roboto_bold};
  background-color: ${({ theme }) => theme.colors.secondary};
`;

export const HeaderDiversity = styled.div`
  height: 50px;
  width: 80%;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  font-size: 30px;
  color: ${({ theme }) => theme.colors.shape};
  font-family: ${({ theme }) => theme.fonts.roboto_bold};
  background-color: ${({ theme }) => theme.colors.primary};
`;

export const DiversityContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerCenter = styled.div`
  display: grid;
  grid-gap: 25px;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 100%;
  padding: 30px 0px 0px 30px;
  flex-wrap: wrap;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fonts.roboto_bold}; ;
`;

export const InConstruction = styled.img`
  margin-top: 30px;
  width: 600px;
  height: 500px;
`;

export const ButtonSeeMoreContainer = styled.aside`
  bottom: 50px;
  width: 100%;
  display: flex;
  position: relative;
  padding-right: 15%;
  justify-content: flex-end;
`;

export const ButtonSeeMore = styled.div`
  height: 50px;
  width: 150px;
  display: flex;
  font-size: 25px;
  cursor: pointer;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.shape};
  font-family: ${({ theme }) => theme.fonts.roboto_bold};
  background-color: ${({ theme }) => theme.colors.secondary};
`;

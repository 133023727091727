import styled from "styled-components";
import { lighten, rgba } from "polished";

export const Container = styled.div`
  display: flex;
  width: 100vw;
  flex: 1;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  flex: 1;
  padding: 50px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 38px;

  > text {
    font-size: 24px;
    font-weight: 700;
  }
`;

export const ListNews = styled.ul``;

export const ItemNews = styled.li`
  display: flex;
  align-items: center;
  width: 90%;
  height: 200px;
  border-radius: 12px;
  margin-bottom: 15px;
  padding-left: 15px;
  border: 2px solid ${({ theme }) => theme.colors.border_secondary};

  &:hover {
    cursor: pointer;
    transition: 0.5s;
    background-color: ${({ theme }) => rgba(theme.colors.primary, 0.1)};
  }
`;

export const TitleItem = styled.text`
  font-size: 20px;
  font-weight: 700;
`;

export const InfosItem = styled.text`
  align-self: flex-end;
  margin: 0px 50px;
  font-size: 18px;
  font-weight: 700;
  color: ${({ theme }) => rgba(theme.colors.primary, 0.3)};
`;

export const DescriptionItem = styled.text`
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 400;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
  text-align: justify;
`;

export const CoverImage = styled.img`
  height: 180px;
  width: 180px;
`;

export const ContentItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90%;
  margin-left: 20px;
`;

export const ButtonAdd = styled.button`
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 40px;
  padding: 5px 30px;
  border: 0;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.primary};
  transition: 0.3s;
  margin: 36px 0;
  color: ${({ theme }) => theme.colors.shape};

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => lighten(0.1, theme.colors.primary)};
  }

  > text {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.shape};
    font-weight: 500;
  }
`;

export const Loader = styled.div`
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid ${({ theme }) => theme.colors.secondary}; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

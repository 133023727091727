/* eslint-disable import/no-anonymous-default-export */
import RobotoRegular from "../assets/fonts/Roboto-Regular.ttf";
import RobotoBold from "../assets/fonts/Roboto-Bold.ttf";

export default {
  colors: {
    primary: "#582c83",
    secondary: "#00aca0",
    shape: "#FFFFFF",
    border: "#f0f0f0",
    error: "#FF0000",
    accent: "#3CB371",
    border_secondary: "#e5e5e5"
  },
  fonts: {
    roboto_regular: RobotoRegular,
    roboto_bold: RobotoBold,
  },
};

import React, { createContext, ReactNode, useContext, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { api } from "../services/api";
import { useAuth } from "./AuthContext";

type NewsProviderProps = {
  children: ReactNode;
};
export interface IRequestNews {
  id: string;
  author: string;
  body: string;
  cover: string;
  description: string;
  title: string;
  reviewer: string;
  tags: string[];
  created_at: Date;
  updated_at: Date;
  canceled_at: Date;
}
interface CreateNew {
  cover: string;
  author: string;
  body: string;
  description: string;
  title: string;
  reviewer: string;
  tags: string[];
}

type NewsContextData = {
  getNewsList: () => void;
  createANew: (data: FormData) => Promise<AxiosResponse>;
  news: IRequestNews[];
};

const NewsContext = createContext({} as NewsContextData);
function NewsProvider({ children }: NewsProviderProps) {
  const { user } = useAuth();
  const [news, setNews] = useState<IRequestNews[]>([] as IRequestNews[]);

  async function getNewsList() {
    try {
      const response = await api.get("news");

      setNews(response && response.data);
    } catch (error) {
      const err = error as AxiosError;
      const dataError = err.response as AxiosResponse;
      throw new Error(dataError.data.message);
    }
  }

  async function createANew(data: FormData) {
    try {
      const response = await api.post("news", data, {
        headers: {
          authorization: `Bearer ${user.token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      return response;
    } catch (error) {
      const err = error as AxiosError;
      const dataError = err.response as AxiosResponse;
      throw new Error(dataError.data);
    }
  }

  return (
    <NewsContext.Provider value={{ getNewsList, news, createANew }}>
      {children}
    </NewsContext.Provider>
  );
}

function useNews() {
  const context = useContext(NewsContext);
  return context;
}

export { useNews, NewsProvider };

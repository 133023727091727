import React from "react";
import {
  ContainerCenter,
  ContainerRoot,
  InConstruction,
  Title,
} from "./styles";
import { HeaderComponent } from "../../components/Header";
import inProgress from "../../assets/inprogress.png";

export const Subscribe: React.FC = () => {
  console.log("Made with love by Krollitte & Kildrak");
  console.log("Coding the world to see if we understand it");
  return (
    <ContainerRoot>
      <HeaderComponent showLogo={true} />
      <ContainerCenter>
        <Title> Em Construção </Title>
        <InConstruction
          className="in-construction"
          src={inProgress}
          alt="In Progress"
        />
      </ContainerCenter>
    </ContainerRoot>
  );
};

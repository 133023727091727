import styled from "styled-components";

interface StatusProps {
  work: boolean;
}

export const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
    80deg,
    ${({ theme }) => theme.colors.primary},
    ${({ theme }) => theme.colors.secondary}
  );
`;

export const FormContainer = styled.div`
  width: 20%;
  height: 40%;
  display: flex;
  border-radius: 18px;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  background-color: ${({ theme }) => theme.colors.shape};
`;

export const LogoContainer = styled.div``;

export const Logo = styled.img`
  width: 100%;
`;

export const InputsContainer = styled.div`
  width: 70%;
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const Input = styled.input`
  padding: 8px 10px;
  font-family: 15px;
  border-radius: 10px;
  font-family: ${({ theme }) => theme.fonts.roboto_regular};
`;

export const ButtonsContainer = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-evenly;
`;

export const MakeLoginButton = styled.div`
  width: 100%;
  height: 35px;
  display: flex;
  cursor: pointer;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.shape};
  font-family: ${({ theme }) => theme.fonts.roboto_regular};
  background-color: ${({ theme }) => theme.colors.secondary};
`;

export const Loader = styled.div`
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid ${({ theme }) => theme.colors.secondary}; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const StatusText = styled.p<StatusProps>`
  color: ${({ theme, work }) =>
    work === true ? theme.colors.accent : theme.colors.error};
`;

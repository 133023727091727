import styled, { css } from "styled-components";
import { lighten } from "polished";

interface StatusProps {
  work: boolean;
}

export const Container = styled.div`
  display: flex;
  flex: 1;

  width: 100vw;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 80px;

  width: 100vw;
  > text {
    font-size: 16px;
    color: #000;
    font-weight: 500;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;

  > text {
    font-size: 21px;
    font-weight: 600;
    ${({ theme }) => css`
      font-family: ${theme.fonts.roboto_bold};
    `}
  }
`;

export const Form = styled.div`
  padding: 16px;
  margin: 24px 0;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  > text {
    font-size: 17px;
    font-weight: 600;
    ${({ theme }) => css`
      font-family: ${theme.fonts.roboto_bold};
    `}
  }
`;

export const ButtonSubmit = styled.button`
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 40px;
  padding: 5px 30px;
  border: 0;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.primary};
  transition: 0.3s;
  margin: 36px 0;
  color: ${({ theme }) => theme.colors.shape};

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => lighten(0.1, theme.colors.primary)};
  }

  > text {
    font-size: 16px;
    color: #000;
    font-weight: 500;
    margin-left: 30px;
  }
`;

export const TagsList = styled.div`
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
`;

export const Tag = styled.div`
  height: 40px;
  display: flex;
  margin-left: 5px;
  padding: 2px 30px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.shape};
  background-color: ${({ theme }) => theme.colors.secondary};
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => lighten(0.1, theme.colors.primary)};
  }
`;

export const Loader = styled.div`
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid ${({ theme }) => theme.colors.secondary}; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const StatusText = styled.p<StatusProps>`
  color: ${({ theme, work }) =>
    work === true ? theme.colors.accent : theme.colors.error};
`;

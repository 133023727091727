import React, { useState } from "react";

import { useEffect } from "react";
import { HashLoader } from "react-spinners";
import * as yup from "yup";
import { useTags } from "../../../hooks/TagsContext";

import { Input } from "../../components/Input";
import { SideMenu } from "../../components/SideMenu";

import {
  Container,
  Content,
  Header,
  InputContainer,
  Form,
  ButtonSubmit,
  TagsList,
  Tag,
  Loader,
  StatusText,
} from "./styles";

interface StatusProps {
  message: string;
  status: boolean;
}

export function CreateTags() {
  const { getTagsList, tags, createNewTag } = useTags();
  const [name, setName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingGetTags, setLoadingGetTags] = useState(false);
  const [status, setStatus] = useState<StatusProps>({} as StatusProps);

  const handleValidation = async (name: string | undefined) => {
    const data = { name };
    try {
      setLoading(true);
      let schema = yup.object().shape({
        name: yup.string().required("Name is required"),
      });

      const isValid = await schema.isValid(data, { abortEarly: false });
      if (isValid) {
        handleSubmitTag();
      } else {
        alert("O nome é obrigatório");
        setLoading(false);
      }
    } catch (error) {
      alert("O nome é obrigatório");
      setLoading(false);
    }
  };

  const handleSubmitTag = async () => {
    try {
      setLoading(true);
      await createNewTag(name);
      setLoading(false);
      window.location.reload();
    } catch (error) {
      setStatus({
        message: String(error),
        status: false,
      });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    async function getTags() {
      try {
        setLoadingGetTags(true);
        await getTagsList();
        setLoadingGetTags(false);
      } catch (error) {
        setStatus({
          message: String(error),
          status: false,
        });
      } finally {
        setLoadingGetTags(false);
      }
    }

    getTags();
  }, []);

  return (
    <Container>
      <SideMenu tags />
      <Content>
        <Header>
          <text> Adicionar uma nova Tag </text>
        </Header>
        <Form>
          <InputContainer>
            <text> Tag: </text>
            <Input
              placeholder="Nome da nova tag"
              onChange={(e) => setName(e.target.value)}
            />
          </InputContainer>

          <ButtonSubmit onClick={() => handleValidation(name)}>
            {loading ? <HashLoader size={20} color={"#fff"} /> : "Adicionar"}
          </ButtonSubmit>
        </Form>
        <text> Tags já criadas: </text>
        <TagsList>
          {loadingGetTags ? (
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Loader />
            </div>
          ) : (
            tags.map((tag, index) => <Tag key={index}>{tag.name}</Tag>)
          )}
          {status.status === false && (
            <StatusText work={status.status}>{status.message}</StatusText>
          )}
        </TagsList>
      </Content>
    </Container>
  );
}

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  ContainerCenter,
  ContainerRoot,
  Content,
  MainNew,
  AsideNews,
  BackgroundImageNew,
  BackgroundImageNewAside,
  TitleContainer,
} from "./styles";
import { HeaderComponent } from "../../components/Header";
import { useNews } from "../../hooks/NewsContext";

export const HomePage: React.FC = () => {
  const navigate = useNavigate();
  console.log("Made with love by Krollitte & Kildrak");
  console.log("Coding the world to see if we understand it");

  const { getNewsList, news } = useNews();

  useEffect(() => {
    function getNews() {
      getNewsList();
    }

    getNews();
  }, []);

  function handleNavigateToReadNew(id: string) {
    navigate(`/news/read/${id}`);
  }

  return (
    <ContainerRoot>
      <HeaderComponent showLogo={true} />
      <ContainerCenter>
        <Content>
          <MainNew>
            <BackgroundImageNew
              onClick={() => {
                handleNavigateToReadNew(news[0]?.id);
              }}
              backgroundImage={news[0]?.cover}
            >
              <TitleContainer
                onClick={() => {
                  handleNavigateToReadNew(news[0]?.id);
                }}
              >
                {news[0]?.title}
              </TitleContainer>
            </BackgroundImageNew>
          </MainNew>
          <AsideNews>
            <BackgroundImageNewAside
              onClick={() => {
                handleNavigateToReadNew(news[1]?.id);
              }}
              backgroundImage={news[1]?.cover}
            >
              <TitleContainer
                onClick={() => {
                  handleNavigateToReadNew(news[1]?.id);
                }}
              >
                {news[1]?.title}
              </TitleContainer>
            </BackgroundImageNewAside>
            <BackgroundImageNewAside
              onClick={() => {
                handleNavigateToReadNew(news[2]?.id);
              }}
              backgroundImage={news[2]?.cover}
            >
              <TitleContainer
                onClick={() => {
                  handleNavigateToReadNew(news[2]?.id);
                }}
              >
                {news[2]?.title}
              </TitleContainer>
            </BackgroundImageNewAside>
          </AsideNews>
        </Content>
      </ContainerCenter>
    </ContainerRoot>
  );
};

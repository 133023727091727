import React, { useState } from "react";
import { IoChevronDown, IoTrashOutline } from "react-icons/io5";

import {
  Container,
  SelectedItem,
  Content,
  IconContainer,
  Dropdown,
  Item,
  RemoveIcon,
} from "./styles";

export type OptionsType = {
  name: string;
  value: string;
};

type Props = {
  onDelete?: any;
  options?: OptionsType[];
  onChange: any;
  values?: OptionsType[];
};

export function MultiSelect({ onDelete, options, onChange, values }: Props) {
  const [open, setOpen] = useState(false);
  const [alter, setAlter] = useState(false);

  return (
    <>
      <Container>
        <Content>
          {values?.map(({ name, value }) => (
            <SelectedItem>
              <text>{name}</text>
              <RemoveIcon
                onClick={() => {
                  onDelete(value);
                  setAlter(!alter);
                }}
              >
                <IoTrashOutline />
                <div style={{ display: alter ? "flex" : "none" }}></div>
              </RemoveIcon>
            </SelectedItem>
          ))}
        </Content>
        <IconContainer onClick={() => setOpen(!open)}>
          <IoChevronDown />
        </IconContainer>
      </Container>
      <Dropdown style={{ display: !open ? "none" : "flex" }}>
        {options &&
          options.map(({ name, value }) => (
            <Item onClick={() => onChange({ name, value })}>
              <text>{name}</text>
            </Item>
          ))}
      </Dropdown>
    </>
  );
}

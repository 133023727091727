import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const ImageContainer = styled.div`
  width: 100%;
`;

export const Logo = styled.img`
  width: 100%;
`;

export const ContactContainer = styled.div`
  width: 25%;
  height: 100vh;
  top: 0;
  left: 1;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: ${({ theme }) => theme.colors.primary};
`;

export const ContactBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
`;

export const Input = styled.input`
  height: 50px;
  width: 100%;
  padding-left: 10px;
  margin-bottom: 10px;
  border-radius: 25px;
`;

export const InputTextArea = styled.textarea`
  height: 150px;
  width: 100%;
  padding: 10px;
  border-radius: 25px;
`;

export const InputButton = styled.button`
  border-width: 0;
  background-color: ${({ theme }) => theme.colors.secondary};
  width: 100%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 25px;
`;

export const InputText = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.shape};
`;

export const BackgroundWrapper = styled.div``;

export const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
`;

import { lighten } from "polished";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 75%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  flex: 1;
  padding: 50px;
`;

export const ImagePreview = styled.img.attrs({
  resizeMode: "contain",
})`
  width: 40%;
  margin-bottom: 60px;
`;

export const ButtonSubmit = styled.button`
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 40px;
  padding: 5px 30px;
  border: 0;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.primary};
  transition: 0.3s;
  margin: 30px 0;
  color: ${({ theme }) => theme.colors.shape};

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => lighten(0.1, theme.colors.primary)};
  }

  > text {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.shape};
    font-weight: 500;
    margin-left: 30px;
  }
`;

export const ContainerRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  align-items: center;
`;
